$(document).ready(function(){

    // Local copy of jQuery selectors, for performance.
    var	my_jPlayer = $("#jquery_jplayer"),
        my_trackName = $("#jp_container .track-name"),
        my_playState = $("#jp_container .play-state"),
        my_extraPlayInfo = $("#jp_container .extra-play-info");

    // Some options
    var	opt_play_first = false, // If true, will attempt to auto-play the default track on page loads. No effect on mobile devices, like iOS.
        opt_auto_play = true, // If true, when a track is selected, it will auto-play.
        opt_text_playing = "Now playing", // Text when playing
        opt_text_selected = "Track selected"; // Text when not playing

    // A flag to capture the first track
    var first_track = true;

    // Change the time format
    $.jPlayer.timeFormat.padMin = false;
    $.jPlayer.timeFormat.padSec = false;
    $.jPlayer.timeFormat.sepMin = " min ";
    $.jPlayer.timeFormat.sepSec = " sec";

    // Initialize the play state text
    my_playState.text(opt_text_selected);

    // Instance jPlayer
    var myPlayer = $("#jquery_jplayer"),
        myPlayerData,
        fixFlash_mp4, // Flag: The m4a and m4v Flash player gives some old currentTime values when changed.
        fixFlash_mp4_id, // Timeout ID used with fixFlash_mp4
        ignore_timeupdate, // Flag used with fixFlash_mp4
        options = {
            ready: function () {
                $(this).jPlayer("setMedia", {
                    mp3: $("#jp_container .track-default").attr("href")
                });
            },
            timeupdate: function(event) {
                if(!ignore_timeupdate) {
                    myControl.progress.slider("value", event.jPlayer.status.currentPercentAbsolute);
                }
            },
            play: function(event) {
                my_playState.text(opt_text_playing);
            },
            pause: function(event) {
                my_playState.text(opt_text_selected);
            },
            ended: function(event) {
                my_playState.text(opt_text_selected);
            },
            swfPath: "/js/jplayer",
            cssSelectorAncestor: "#jp_container",
            supplied: "mp3",
            wmode: "window"
        },
        myControl = {
            progress: $(options.cssSelectorAncestor + " .jp-progress-slider"),
            volume: $(options.cssSelectorAncestor + " .jp-volume-slider")
        };

    myPlayer.jPlayer(options);

    // A pointer to the jPlayer data object
    myPlayerData = myPlayer.data("jPlayer");

    // Define hover states of the buttons
    $('.jp-gui ul li').hover(
        function() { $(this).addClass('ui-state-hover'); },
        function() { $(this).removeClass('ui-state-hover'); }
    );

    // Create the progress slider control
    myControl.progress.slider({
        animate: "fast",
        max: 100,
        range: "min",
        step: 0.1,
        value : 0,
        slide: function(event, ui) {
            var sp = myPlayerData.status.seekPercent;
            if(sp > 0) {
                // Apply a fix to mp4 formats when the Flash is used.
                if(fixFlash_mp4) {
                    ignore_timeupdate = true;
                    clearTimeout(fixFlash_mp4_id);
                    fixFlash_mp4_id = setTimeout(function() {
                        ignore_timeupdate = false;
                    },1000);
                }
                // Move the play-head to the value and factor in the seek percent.
                myPlayer.jPlayer("playHead", ui.value * (100 / sp));
            } else {
                // Create a timeout to reset this slider to zero.
                setTimeout(function() {
                    myControl.progress.slider("value", 0);
                }, 0);
            }
        }
    });
    // Create the volume slider control
    myControl.volume.slider({
        animate: "fast",
        max: 1,
        range: "min",
        step: 0.01,
        value : $.jPlayer.prototype.options.volume,
        slide: function(event, ui) {
            myPlayer.jPlayer("option", "muted", false);
            myPlayer.jPlayer("option", "volume", ui.value);
        }
    });

    // Create click handlers for the different tracks
    $("#jp_container .track").click(function(e) {
        $("#jp_container .track").removeClass("actief");
        $(this).addClass("actief");
        my_trackName.text($(this).text());
        my_jPlayer.jPlayer("setMedia", {
            mp3: $(this).attr("href")
        });
        my_jPlayer.jPlayer("play");
        first_track = false;
        $(this).blur();
        return false;
    });


});