const cw = (cw || {});

(($) => {

    /**
     * Main function
     */
    function Main() {

        const self = this;

        this.init = () => {

            if( $('.editmode').length == 0) {
                // frontend
            }
        };
    }

    $(document).ready(() => {
        cw.main = new Main();
        cw.main.init();
    });

})(jQuery);