

var isMobile = false; //initiate as false
// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;


function zoekenmobiel() {
    var widthbody = $(document).width();
    if(widthbody < 768) {
        var htmenu = $("header .topbar").outerHeight();
        var hmenu = $(".menubalk").outerHeight();
        var totaal = htmenu + hmenu;
        $(".headertopheader").css({'top':totaal+"px"});
        $(window).scroll(function (event) {
            var scroll = $(window).scrollTop();
            if(scroll < 100) {
                $(".headertopheader").css({'top':totaal+"px"});
            } else {
                $(".headertopheader").css({'top':"0px"});
            }
        });
    }
}

function loadPDFJS(pageUrl,divid) {

    PDFJS.workerSrc = '/website/static/lib/pdf.worker.js';
    var currentPage = 1;
    var pages = [];
    var globalPdf = null;
    var container = $("#"+divid+" .liedbladpdfin");

    function renderPage(page)
    {
        var canvas = document.createElement('canvas');

        if (isMobile)
        {
            var viewport = page.getViewport( 4 );
            var DPI = 72; // increase to improve quality
            var PRINT_OUTPUT_SCALE = DPI/72;

        }
        else
            var viewport = page.getViewport( $(".liedblad").width() / page.getViewport(1.0).width );

        container.append(canvas);
        var context = canvas.getContext('2d');

        //canvas.height = 1000; // $(".liedblad").height() // viewport.height;
        //canvas.width = $(".liedblad").width() // viewport.width;

        if (isMobile)
        {
            canvas.width = Math.floor(viewport.width * PRINT_OUTPUT_SCALE);
            canvas.height = Math.floor(viewport.height * PRINT_OUTPUT_SCALE);
            canvas.style.width = '99%';
        }
        else
        {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.style.width = '99%';
        }

        var renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        page.render(renderContext).then(function () {
            if (currentPage < globalPdf.numPages) {
                pages[currentPage] = canvas;
                currentPage++;
                globalPdf.getPage(currentPage).then(renderPage);
            } else {
                // Callback function here, which will trigger when all pages are loaded
            }
        });
    }
    PDFJS.getDocument(pageUrl).then(function (pdf) {
        if(!globalPdf){
            globalPdf = pdf;
        }
        pdf.getPage(currentPage).then(renderPage);
    });
}

var doResize = false;
$(window).resize(function(){
    var newWidth = $(window).width();
    if(newWidth !== cachedWidth){
        cachedWidth = newWidth;
        doResize = true;
    }
    else
        doResize = false;
});

var cachedWidth = $(window).width();
var rtime;
var timeout = false;
var delta = 200;

$(window).resize(function() {
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});

function resizeend() {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        if (doResize)
        {
            if ($(".pdfcontent").length) {
                $(".pdfcontent").each(function() {
                    var pdf = $(this).children(".pdflinkje").html();
                    var id = $(this).attr("id");
                    $("#"+id+" .liedbladpdfin").html("");
                    loadPDFJS(pdf,id);
                });
            }
        }
    }
}


$(document).ready(function(){
    if ($('#liedpage').length) {
        var id = $("#liedpage #songtitle").html();
        ga('send', 'event', 'Lied pagina', 'Bezoek', id, 1);

        $("#liedpage .jp-play").click(function() {
            var playcheck = $("#liedpage #songplay").html();
            if(playcheck == 0) {
                $("#liedpage #songplay").html(1);
                ga('send', 'event', 'Muziek', 'Play', id, 1);
            }
        });
        $("#liedpage .player .nummers a").click(function() {
            var song = $(this).text();
            ga('send', 'event', 'Muziek', song, id, 1);
        });
        setTimeout(function() {
            $(window).scrollTop($(window).scrollTop()+1);
        },500);
        setTimeout(function() {
            $(window).scrollTop(0);
        },1000);
    }


    $('body').tooltip({
        selector: "[data-tooltip=tooltip]",
        container: "body"
    });
    if ($("input[name$='websiteurl']").length) {
        $("input[name$='websiteurl']").val("");
    }
    if ($(".pdfcontent").length) {
        $(".pdfcontent").each(function() {
            var pdf = $(this).children(".pdflinkje").html();
            var id = $(this).attr("id");
            loadPDFJS(pdf,id);
        });
    }
    if ($(".liedpagetitle").length) {
        var widthbody = $(document).width();
        if(widthbody < 768) {
            var $target = $('.liedpagetitle');
            var navheight = $(".navbar-fixed-top").height();
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - navheight
            }, 700, 'swing', function() {
            });
        }
    }
    if ($(".sluitreclamehome").length) {
        $(".sluitreclamehome").click(function(){
            $("#footer").removeClass("footerfixed");
            $("#footer").addClass("footeruit");
            $.ajax({
                type: "POST",
                url: "/setfooter.php",
                success: function(data) {
                }
            });
        });
    }
    if ($(".schoolbordversie").length) {
        jQuery.fn.popupwindow=function(a){var b=a||{};return this.each(function(a){var c,d,e,f,g,h;if(e=(jQuery(this).attr("rel")||"").split(","),c={height:600,width:600,toolbar:0,scrollbars:0,status:0,resizable:1,left:0,top:0,center:0,createnew:1,location:0,menubar:0,onUnload:null},1==e.length&&1==e[0].split(":").length)g=e[0],"undefined"!=typeof b[g]&&(c=jQuery.extend(c,b[g]));else for(var i=0;i<e.length;i++)f=e[i].split(":"),"undefined"!=typeof c[f[0]]&&2==f.length&&(c[f[0]]=f[1]);1==c.center&&(c.top=(screen.height-(c.height+110))/2,c.left=(screen.width-c.width)/2),d="location="+c.location+",menubar="+c.menubar+",height="+c.height+",width="+c.width+",toolbar="+c.toolbar+",scrollbars="+c.scrollbars+",status="+c.status+",resizable="+c.resizable+",left="+c.left+",screenX="+c.left+",top="+c.top+",screenY="+c.top,jQuery(this).bind("click",function(){var b=c.createnew?"PopUpWindow"+a:"PopUpWindow";return h=window.open(this.href,b,d),c.onUnload&&(unloadInterval=setInterval(function(){h&&!h.closed||(clearInterval(unloadInterval),c.onUnload.call($(this)))},500)),h.focus(),!1})})};
        $(".schoolbordversie").popupwindow();
        $(".liedblad .nav-tabs li a").click(function(){
            var id = $(this).attr("href").replace("#tab","");
            if(id != "#login") {
                $(".schoolbordversie").attr("href","/pdf/"+id+"/");
            }
            console.log(id);
        });
    }
    if ($(".printen").length) {
        jQuery.fn.popupwindow=function(a){var b=a||{};return this.each(function(a){var c,d,e,f,g,h;if(e=(jQuery(this).attr("rel")||"").split(","),c={height:600,width:600,toolbar:0,scrollbars:0,status:0,resizable:1,left:0,top:0,center:0,createnew:1,location:0,menubar:0,onUnload:null},1==e.length&&1==e[0].split(":").length)g=e[0],"undefined"!=typeof b[g]&&(c=jQuery.extend(c,b[g]));else for(var i=0;i<e.length;i++)f=e[i].split(":"),"undefined"!=typeof c[f[0]]&&2==f.length&&(c[f[0]]=f[1]);1==c.center&&(c.top=(screen.height-(c.height+110))/2,c.left=(screen.width-c.width)/2),d="location="+c.location+",menubar="+c.menubar+",height="+c.height+",width="+c.width+",toolbar="+c.toolbar+",scrollbars="+c.scrollbars+",status="+c.status+",resizable="+c.resizable+",left="+c.left+",screenX="+c.left+",top="+c.top+",screenY="+c.top,jQuery(this).bind("click",function(){var b=c.createnew?"PopUpWindow"+a:"PopUpWindow";return h=window.open(this.href,b,d),c.onUnload&&(unloadInterval=setInterval(function(){h&&!h.closed||(clearInterval(unloadInterval),c.onUnload.call($(this)))},500)),h.focus(),!1})})};
        $(".printen").popupwindow();
    }

    $(".advnew").click(function(){
        if($(this).find("a").attr("href") != "") {
            window.open($(this).find("a").attr("href"),'_blank');
            return false;
        }
    });

    $(".fullclick").click(function(){
        window.location=$(this).find("a").attr("href");
        return false;
    });
    $(".zoekensidebar ul li").click(function(){
        var main = $(this).parent().attr("id").replace("maincat","");
        var waarden = $(this).html();
        var classname = $(this).attr("class");
        $(".zoekenaccordion #zcat"+main+" h4 span").remove();
        if(classname != "actief") {
            $(".zoekenaccordion #zcat"+main+" h4 a").append('<span>'+waarden+'</span>');
        }
        $(".zoekenaccordion #zcat"+main).removeClass("active");
        $(".zoekenaccordion #zcatc"+main).removeClass("in");
    });
    $(".pagination a").click(function(){
        $(".pagination li").removeClass("actief");
        $(this).parent().addClass("actief");
    });
    $('[data-toggle="tooltip"]').tooltip();
    //var clickedNotify = false;
    //$('[data-toggle="tooltip"]').mouseleave(function() { if (!clickedNotify) { $('[data-toggle="tooltip"]').tooltip({placement: 'bottom'}).tooltip('show'); } });

    $('#accordion').on('show.bs.collapse', function(e) {
        $(e.target).prev('.panel-heading').addClass('active');
    }).on('hide.bs.collapse', function(e) {
        $(e.target).prev('.panel-heading').removeClass('active');
    });
    $(".zoekenaccordion ul li").click(function() {
        var maincat = $(this).parent().attr("id");
        var id = $(this).attr("id").replace("sub","");
        var classnaam = $(this).attr("class");
        if(classnaam == "actief") {
            $("input[name='"+maincat+"']").val("");
            $(".zoekenaccordion ul#"+maincat+" li").removeClass("actief");
        } else {
            $("input[name='"+maincat+"']").val(id);
            $(".zoekenaccordion ul#"+maincat+" li").removeClass("actief");
            $(this).addClass("actief");
        }
    });
    $("select[name=setp]").change(function() {
        $(".setp").submit();
    });
    $("select.checkregister").change(function() {
        var check = $(this).find("option:selected").attr("class");
        if(check == "overigeregistreer") {
            $("#overigeregistreer").removeClass("hidden");
        } else {
            $("#overigeregistreer").addClass("hidden");
        }
    });
    $(".sharebox .fa-heart").click(function(){
        var classname = $(this).attr("class").replace("fa fa-heart ","");
        var song_id = $("#song_id").html();

        var dataString = "song_id="+song_id+"&actie="+classname;
        $.ajax({
            type: "POST",
            url: "/inc/savesong.php",
            data: dataString,
            success: function(data) {
                if(classname == "geenfavo") {
                    $(".sharebox .fa-heart").removeClass(classname);
                    $(".sharebox .fa-heart").addClass("welfavo");
                } else {
                    $(".sharebox .fa-heart").removeClass(classname);
                    $(".sharebox .fa-heart").addClass("geenfavo");
                }
            }
        });
    });

    $("#totop").click(function(){
        $('html, body').stop().animate({
            'scrollTop': 0
        }, 300, 'swing', function() {

        });
    });
    if ($("#prikbord").length) {
        $("#prikbord").css({"height": $(window).height()+"px"});
        $("#prikbord .prikbordframe").css({"height": ($("#prikbord").height() - 30)+"px"});
        $('.prikbordlabel').on('click', function() {
            if ($(".prikbordlabel .nietingelogd").length) {
                $('.login-modal').modal('toggle');
            } else {
                var panel = $('.prikbordinrow');
                if (panel.hasClass("visible")) {
                    panel.removeClass('visible');
                    $("#prikbord").animate({'right':'-260px'});
                } else {
                    panel.addClass('visible');
                    $("#prikbord").animate({'right':'0px'});
                }
                return false;
            }
        });
        if ($("#prikbord.openprik").length) {
            $('.prikbordinrow').addClass('visible');
            $("#prikbord").animate({'right':'0px'});
        }
        $('.prikbordinrow').height($(document).height());

        $(".prikbordadd .header").click(function(e) {
            $(".prikbordadd .content").toggle();
        });

        $('.page-content, .topbar, .menubalk').on('click', function() {
            if ($(".prikbordlabel .nietingelogd").length) {
            } else {
                var panel = $('.prikbordinrow');
                if (panel.hasClass("visible")) {
                    panel.removeClass('visible');
                    $("#prikbord").animate({'right':'-260px'});
                }
            }
        });
    }
    $("#mobzoeken").click(function(e) {
        $(".headertopheader").toggle();
    });

    var widthbody = $(document).width();
    if(widthbody < 768) {
        setTimeout(function() { zoekenmobiel(); }, 1000);
        setTimeout(function() { zoekenmobiel(); }, 2000);

        $(".zoekensidebar .topgrijs h3").click(function(e) {
            var i = $(".zoekensidebar .topgrijs h3 span").html();
            console.log(i);
            if(i == '<i class="fa fa-caret-down"></i>') {
                $(".zoekensidebar .topgrijs h3 span").html('<i class="fa fa-caret-up"></i>');
            } else {
                $(".zoekensidebar .topgrijs h3 span").html('<i class="fa fa-caret-down"></i>');
            }
            $(".zoekenboxomalles").toggle();
        });

    }
});

$(window).scroll(function (event) {
    var height = $(document).height();
    var height2 = $(window).height();
    var scroll = $(window).scrollTop();
    var height3 = height2 + scroll;
    var verschil = height - height3;
    // Do something
    if(scroll > 0) {
        $(".navbar").addClass("menuscroll");
    } else {
        $(".navbar").removeClass("menuscroll");
    }
    if(scroll > 300) {
        $("#totop").fadeIn();
    } else {
        $("#totop").fadeOut();
    }
    if (!$(".footeruit").length) {
        if(verschil < 150) {
            $("#footer").removeClass("footerfixed");
        } else {
            $("#footer").addClass("footerfixed");
        }
    }
});
/*
$(window).on('hashchange', function() {
    var url = document.URL;
    var url2 = url.replace("http://","");
    var url3 = url2.replace("www.","");
    var hash = window.location.hash;
    var hash = hash.replace("#","");
    if(hash != null) {
        var $target = $('.anc'+hash);
        var navheight = $(".navbar-fixed-top").height();
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - navheight
        }, 700, 'swing', function() {
            window.location.hash = '#' + hash;
        });
    }
});
*/